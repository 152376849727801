import React,{useRef,useState,useEffect,useContext} from 'react'
import {Alert,Container,Form,Button,Card} from 'react-bootstrap';
import axios from 'axios'
import {UserContext} from './context/UserContext'
import {Navigate,useNavigate} from 'react-router-dom'
import logo from './logo.png'
import './css/App.css'


export default function Login() {
  const [loginError,setLoginError]=useState(false)
  const [loginSuccess,setLoginSuccess]=useState(false)
  const [isLoading,setIsLoading]=useState(false)
  const {userName,setUserName}=useContext(UserContext)
  const {isAuth,setIsAuth}=useContext(UserContext)
  const {apiToken,setApiToken} = useContext(UserContext)
  const {userToken,setUserToken} = useContext(UserContext)

  const UN=useRef()
  const PWD=useRef()
  //const history=useHistory()
  const navigate=useNavigate ()

  const login=(e)=>{
      e.preventDefault()

      const loginData={
          UN:UN.current.value,
          PWD:PWD.current.value
      }

      // const usr={
      //     id:1,
      //     UserName:'Kostas Kapetanakis',
      //     token:'xxxxx'       
      // }

     // validateUser(usr)
      axios.post(process.env.REACT_APP_API_SERVER+'admin/login',loginData,{})
      .then(res=>{
          console.log(res.data)
          if (res.data.err==0){
              setIsLoading(true)
              validateUser(res.data)
             
          }else{
              setIsAuth(false)
              // setUserToken(null)
              // setLoginSuccess(false)
              setLoginError(true)  
              setTimeout(() => {
                  setLoginError(false) 
              }, 3000);                              
          }
      })

  }


  const validateUser=(data)=>{
    console.log(data)
      setIsAuth(true)
      setUserToken(data.id)
      setUserName(data.UserName)
      setLoginSuccess(true)
      setLoginError(false)
      setApiToken(data.token)

      sessionStorage.setItem('isAuth',1)
      sessionStorage.setItem('userToken',data.id)
      sessionStorage.setItem('userName',data.UserName)
      sessionStorage.setItem('apiToken',data.token)
      sessionStorage.setItem('clipBoard',{ContentID:0,ContentTitle:''})

      setTimeout(() => {

          navigate('/dashboard')
      }, 3000);  

  }
    return (
        <>
            <Alert className="topAlert" style={{backgroundColor:'#2c2c2c',color:'white',fontSize:'xx-large',height:'12vh'}}>
            <img src={logo} style={{height:'8vh'}}/> 123High Admin
            </Alert>  

            <Container>
                <Card>
                   
                    <Card.Body>
                        <Card.Text>
                            <Form onSubmit={login}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>User Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter user name" required ref={UN}/>
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter password" required ref={PWD}/>
                                </Form.Group>
                                <br/>
                                <p align="right">
                                    <Button  variant="dark" type="submit" style={{width:"150px"}}>
                                        Login
                                    </Button>
                                </p>

                            </Form> 
                            {loginSuccess && 
                                <Alert variant="dark">
                                Welcome {userName}. Please wait...
                                </Alert>                              
                            } 

                            {loginError && 
                                <Alert variant="danger">
                                Login failed
                                </Alert>                              
                            }                            
                        </Card.Text>
                        
                    </Card.Body>
                </Card>
            </Container>

        </>
    )
}
