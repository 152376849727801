import React, {Fragment,useState,useEffect} from 'react'

import axios from 'axios'


const FileUploadSports = (props) => {

    const [file,setFile]=useState('')
    const [fileName,setFileName]=useState('Please select an MP4 file')
    const [upLoading, setUpLoading]=useState('none')
    const [uploadPercentage, setUploadPercentage]=useState(0)
    const [url,setUrl]=useState('')
    const [title,setTitle]=useState('')
    const [photo,setPhoto]=useState('')


    useEffect(() => {


        loadFile(props.id)

        if (props.type==3){
            setUrl(process.env.REACT_APP_API_SERVER+'uploadprogramphoto.php?id='+props.id)
            setFileName('Please select an image file')
            setTitle('Upload  Photo.')
        }


        //console.log(props.type)
    }, [props.type])


    useEffect(()=>{
        loadFile(props.id)
    },[props.id])

    const fileChanged=e=>{

        try{



            if (props.type==2 || props.type==3 || props.type==4 || props.type==5){

                if (e.target.files[0].type.indexOf('image/')>-1){
                    setFile(e.target.files[0])
                    setFileName(e.target.files[0].name)
                }else{
                    alert('Please Select an IMAGE file')
                }
            }            
            
        }catch{

        }

    }

    const uploadFile=async e=>{
        e.preventDefault()
      //console.log(file)
        const formData=new FormData()
        formData.append('file',file)
        formData.append('id',props.id)
        try{
            setUpLoading('block')
            axios.post(process.env.REACT_APP_API_SERVER+'admin/sports/photo',formData,{
                headers:{
                    'Content-Type':'multipart/form-data',
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                },
                onUploadProgress: ProgressEvent=>{
                    setUploadPercentage(parseInt(Math.round(ProgressEvent.loaded*100/ProgressEvent.total)))
                }
            }).then(res=>{
                setUpLoading('none')
                document.getElementById('customFile').value=null
                
                setUploadPercentage(0)

                if (res.data.err==0){
                    loadFile(props.id)
                }else{
                    alert('An Error has occured! Please Try Again')
                }

            })



            
        }catch(err){

        }
    }

    const loadFile=(id)=>{
        const t=Date.now()
        setPhoto(process.env.REACT_APP_API_SERVER+'admin/filessports/'+id+'/'+t)

      }


    return (
        
        <Fragment>
            <div className="alert alert-success" role="alert">
            {title}
            </div>
            <form onSubmit={uploadFile}>
            <div className="custom-file mt-4">
                <input required onChange={fileChanged} type="file" className="custom-file-input" id="customFile"/>
                <label className="custom-file-label" htmlFor="customFile">
                    {fileName}
                </label>
            </div>
            <br/><br/>                
            <button type="submit" disabled={upLoading=='block'} className="btn btn-success">Upload {props.type==1 ? 'Video' : 'Photo'}</button>
            </form>
            <br/>
            <div className="alert alert-danger" role="alert" style={{display:`${upLoading}`}}>
                Uploading! Please Wait!
            </div>
            <div className="progress">
            <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width:`${uploadPercentage}%`}}>{uploadPercentage}%</div>
            </div> 
            <hr/>
           
            <img src={photo} width="200"/>
        </Fragment>
    )


}

export default FileUploadSports;