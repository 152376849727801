import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Tabs,Tab} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import {FaBookOpen, FaUser} from 'react-icons/fa'
import ChallengeFormReview from './ChallengeFormReview';
import moment from 'moment'

export default function Dashboard() {

    const [form,setForm]=useState(false)
    const [item,setItem]=useState({})
    const [isLoading,setIsLoading]=useState(true)
    const [data,setData]=useState([])
    const [sports,setSports]=useState([])
    


    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },


        {
            name: 'Χρήστης',
            selector: row => <Link to="#" onClick={()=>{showForm(row,true)}}>{row.FirstName} {row.LastName}</Link>,
            sortable: true
        },

       

        {
            name: 'Challenge',
            selector: row =>  row.Title,
            sortable: true
        }, 

        {
          name: 'Δημοσίευση',
          selector: row =>  moment(row.DateCreated).format('DD/MM/YYYY'),
          sortable: true
      }, 

    //   {
    //     name: '',
    //     selector: row => <img width="200" src={process.env.REACT_APP_VIDEOSERVER_API+''+row.FileID+'.jpg'}/>,
    //     sortable: true
    // }, 


      
    ]


    useEffect(()=>{
        setIsLoading(true)
        loadData()
    },[form])


    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/dashboard',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
         console.log(res.data.results) 
          setData(res.data.results)
          loadSports()
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }


      const loadSports=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/sports',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
           // console.log(res.data.results) 
          setSports(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })           
      }

    const showForm=(row,a)=>{
        setForm(a)
        setItem(row)
    }


    const sportsList=sports.map((item,index)=>{
        

        return(
            <Tab eventKey={index} title={item.SportName}>
            <br/>
            <DataTable
                columns={columns}
                data={data.filter(xitem=>xitem.SportID==item.SportID)}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                />   

          </Tab>            
        )
    })

    const dataList=data.map(item=>{
        return(
            <tr id={item.id}>
                <td><Link to="#" onClick={()=>{showForm(item.UUID,true)}}>{item.Title}</Link></td>

                <td><div dangerouslySetInnerHTML={{ __html:item.Descr}}/></td>
                <td>
                {/* <img src={`${process.env.REACT_APP_SERVER}images/programs/${item.Photo}`} width="100"/> */}
                {item.IsActive}
                </td>
            </tr>
        )
    })

    return (
        <>
            {isLoading && 

            <Alert variant="warning" >Loading, please wait....</Alert>
            }

            {!isLoading &&             
            <>
            <Alert variant="secondary"><h4><FaUser/> {form ? 'Προσθήκη/Επεξεργασία User' : 'Dashboard'}</h4></Alert>
                <Container fluid>

               

                {form && 
                    <>
                    <button className="btn btn-dark" onClick={()=>{showForm(0,false)}}>Dashboard</button>
                    <ChallengeFormReview item={item} showForm={showForm} setForm={setForm} loadData={loadData}/>
                    </>
                }

                {!form &&
                    <>

                    <br/><br/>
                    
                        <Tabs>
                            {sportsList}
                        </Tabs>

                    </>
                
                }
                </Container>
                </>
            }
        </>
    )
}
