import React, {useState,useContext,createContext,useEffect,useRef}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Row,Col,Accordion} from 'react-bootstrap'
import axios from 'axios'
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop,faPlus, faShoppingBasket,faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function EShopProducts() {
    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [show, setShow] = useState(false);
    const [record,setRecord]=useState({})
    const [url,setUrl]=useState('')
    const [paymentLink,setPaymentLink]=useState('')
    const [sports,setSports]=useState([])




    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        {
            name: '',
            selector: row => <Button variant="secondary" onClick={()=>showModal(row.id)}><FontAwesomeIcon icon={faEdit}/></Button>,
            sortable: true,
            maxWidth:'5px'
        },

        {
            name: 'Όνομα',
            selector: row =>  row.Title,
            sortable: true,
            maxWidth:'200px'
        },
        {
            name: 'Συνδρομή',
            selector: row =>  row.LicenceDurationMonths+' months',
            sortable: true,
            maxWidth:'100px'
        },

        {
            name: 'Άθλημα',
            selector: row =>  row.SportName,
            sortable: true,
            maxWidth:'200px'
        },  

        
        {
            name: 'Τιμή',
            selector: row =>  row.Price,
            sortable: true,
            maxWidth:'50px'
        },  

        {
            name: 'EShop Link',
            selector: row =>  url+'/#/checkout/'+row.UUID,
            sortable: true
        },  

    ]
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        loadData()
    },[])


    const loadSports=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/sports',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
           // console.log(res.data.results) 
          setSports(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }


    const productpaymentLink=(UUID)=>{
       
        axios.get(process.env.REACT_APP_API_SERVER+'admin/eshopproducts/paymentlink/'+UUID,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
            if (res.data.err==0){
                setPaymentLink(res.data.url) 
            }else{

                setPaymentLink('ERROR: '+res.data.msg.code) 
            }
            

          
        }).catch(err=>{

            console.log(err)
        })          
      }


      const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/eshopproducts',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
         console.log(res.data.results) 
          setData(res.data.results)
          loadSettings()
          loadSports()
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }

      const loadSettings=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/eshopsettings',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{

          //setData(res.data.results)
          setUrl(res.data.results[0].EShopUrl)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }

      const showModal=(id)=>{
        setPaymentLink('')
        if (id==0){
            setRecord({ModalTitle:'ΝΕΑ ΕΓΓΡΑΦΗ', id:0,Title:'',Price:0,Descr:'',NumberOfCountries:0,SportID:0,LicenceDurationMonths:0,SportName:'',TopListCreation:0})
            setShow(true)
        }else{
            const recObj=data.filter(item=>item.id==id)[0]
            
            setRecord({ModalTitle:recObj.Title,NumberOfCountries:recObj.NumberOfCountries, Descr:recObj.Descr,UUID:recObj.UUID,id:recObj.id,Title:recObj.Title,Descr:recObj.Descr,Price:recObj.Price,SportID:recObj.SportID,LicenceDurationMonths:recObj.LicenceDurationMonths,SportName:recObj.SportName,TopListCreation:recObj.TopListCreation})
            setShow(true)            
        }

      }

      const save=(e)=>{
        e.preventDefault()

        axios.post(process.env.REACT_APP_API_SERVER+'admin/eshopproducts',record,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                }            
            })
          .then(res=>{
            if (res.data.err==0){
                loadData()
            }
            setShow(false)
            alert(res.data.msg)
        })
      }

      const deleteRecord=()=>{
        if (window.confirm('ΔΙΑΓΡΑΦΗ PRODUCT!!!! ΣΙΓΟΥΡΑ???')){
            axios.delete(process.env.REACT_APP_API_SERVER+'admin/eshopproducts/'+record.id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                    }            
                })
              .then(res=>{
                if (res.data.err==0){
                    loadData()
                }
                setShow(false)
                alert(res.data.msg)
            })
        }
      }

      const sportsList=sports.map(item=>{
        return(
            <option value={item.SportID}>{item.SportName}</option>
        )
      })

      const changeSport=(id)=>{
        setRecord({...record,SportID:id,SportName:sports.filter(item=>item.SportID==id)[0].SportName})
 
      }
    return (
        <>
            {isLoading &&
            <Alert variant='warning'>Loading....Please Wait</Alert>

            }

            {!isLoading &&
            <>
            <Alert variant="secondary">
                <table width="100%">
                    <tr>
                        <td><h5>EShop Products</h5></td>
                        <td align="right">
                        <Button variant="dark" onClick={()=>showModal(0)}><FontAwesomeIcon icon={faPlus}/></Button>
                        </td>
                        <td width="10"></td>
                    </tr>
                </table>
                    
            </Alert>




                <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                />            
            
            </>
            }

                <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
                <Modal.Header closeButton>
                <Modal.Title>
                    {record.ModalTitle}
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                <Form onSubmit={save}>

                        <>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><b>Τίτλος</b></Form.Label>
                            <Form.Control type="text" maxLength={250} required value={record.Title} onChange={(e)=>setRecord({...record,Title:e.target.value})}/>
                        </Form.Group>
                        <br/>

                      
                        <Form.Group>
                            <Form.Label><b>Άθλημα</b></Form.Label>  
                            <Form.Control required as="select" value={record.SportID} onChange={(e)=>changeSport(e.target.value)} style={{width:'200px'}}>
                                
                                <option value=''>[ΕΠΙΛΕΞΤΕ ΑΘΛΗΜΑ]</option>
                                {sportsList}
                            </Form.Control>
                        </Form.Group>  
                        <br/>                      
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><b>Τιμή</b></Form.Label>
                            <Form.Control type="number" min={0} required value={record.Price} onChange={(e)=>setRecord({...record,Price:e.target.value})}/>
                        </Form.Group>                        
                        <br/>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><b>Διάρκεια Συνδρομής σε Μήνες</b> </Form.Label>
                            <Form.Control type="number" min={0} step={1} required value={record.LicenceDurationMonths} onChange={(e)=>setRecord({...record,LicenceDurationMonths:e.target.value})}/>
                        </Form.Group>                        
                        <br/>  
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><b>Σε πόσες χώρες θα έχει πρόσβαση</b> (0 για όλες)</Form.Label>
                            <Form.Control type="number" min={0} step={1} required value={record.NumberOfCountries} onChange={(e)=>setRecord({...record,NumberOfCountries:e.target.value})}/>
                        </Form.Group>                        
                        <br/> 
                        <Form.Group>
                            <Form.Label><b>Δημιουργία Top List</b></Form.Label>  
                            <Form.Control as="select" value={record.TopListCreation} onChange={(e)=>setRecord({...record,TopListCreation:e.target.value})} style={{width:'200px'}}>
                            <option value='0'>ΟΧΙ</option>
                                <option value='1'>ΝΑΙ</option>
                                
                            </Form.Control>
                        </Form.Group>
                        <br/>                       
                        <Form.Group controlId="formBasicEmail">
                        <Form.Label><b>Περιγραφή</b></Form.Label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={record.Descr}

                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setRecord({...record,Descr:data.replace("'","`")})
                                } }

                            />
                        </Form.Group>
                        {record.id>0 &&
                        <> 
                        <br/>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><b>EShop Link</b></Form.Label>
                            <Form.Control  disabled type="text" value={url+'/#/checkout/'+record.UUID}/>
                        </Form.Group>
                        </>
                        }
                        {record.id>0 &&
                        <>
                        <br/>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><b>Stripe Payment Link</b></Form.Label>
                            <Form.Control  disabled type="text" value={paymentLink}/>
                            <p align="right">
                                <br/>
                                <Button variant="secondary" onClick={()=>productpaymentLink(record.UUID)}>Payment Link</Button>
                            </p>
                        </Form.Group>
                        </>
                        }

                        <br/><br/>



                        <Row><Col></Col>
                        <Col md="auto">

                            {record.id>0 &&
                            <>
                            <Button  variant="danger" style={{width:"150px"}} onClick={deleteRecord}>
                                Διαγραφή
                            </Button>
                            &nbsp; 
                            </>
                            }

                            <Button  variant="success" type="submit" style={{width:"150px"}}>
                                Αποθήκευση
                            </Button>                      
                        </Col>
                      </Row>                        
                        </>
                   </Form> 

                    

                </Modal.Body>

            </Modal>   
        </>
    )
}
