import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Card} from 'react-bootstrap'
import './mobile.css'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import axios from 'axios'
import ReactPlayer from 'react-player'
import Logo from'../123FP_Logo.png'

export default function Challenges() {
    const {uid}=useParams()
    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(true)
    const navigate=useNavigate ()

    useEffect(()=>{

        loadData()
    },[])

    const loadData=()=>{
        //setIsLoading(true)
        console.log(1)
        axios.get(process.env.REACT_APP_API_SERVER+'mobile/challenges/'+uid)
        .then(res=>{
            
            console.log(res.data.results)
          setData(res.data.results)
          //setIsLoading(false)
          
        }).catch(err=>{
            //setIsLoading(false)
            console.log(err)
        })          
    }

    const cardsList=data.map(item=>{
        if (item.IsChallenge==1){
        return(
            <Card style={{borderColor:'#f0f0f0',marginTop:10}} key={item}>
            <Card.Header style={{backgroundColor:'#fff',display:'flex',alignItems:'center'}}>
                    <div>
                    <b>{item.Title}</b><br/>
                    <i>{item.Descr}</i>
                    </div>

                    <div>
                        <Button onClick={()=>navigate('/mobile/challengedetails/'+uid+'/'+item.UUID)}>---</Button>
                    </div>
            </Card.Header>

            {item.Photo &&
            <Card.Img style={{borderRadius:'15px',padding:'5px'}} variant="bottom" src={process.env.REACT_APP_API_SERVER+'admin/files/'+item.UUID+'/'+item.Timestamp}  />
            }
            
            {item.VideoID &&
            
            <Card.Body>


                    <ReactPlayer 
                    url={`https://5f06d0924ebcd.streamlock.net/mlg/${item.VideoID}.smil/playlist.m3u8`}
                    controls={true}
                    light={process.env.REACT_APP_VIDEOSERVER_API+''+item.VideoID+'.jpg'}
                    width='100%'
                    style={{
                        border: '3px solid #ccc',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        /* Other inline styles */
                      }}
                    />   
            </Card.Body>
            }
            <Card.Footer style={{backgroundColor:'#fff',display:'flex',alignItems:'center',borderTop:'0px solid #fff'}}>



            </Card.Footer>

        </Card> 
        )}
    })
    return (
        <Container>
            {cardsList}
        </Container>
    )
}
