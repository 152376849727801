import React, {useState,useContext,createContext,useEffect,useRef}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Row,Col,Accordion} from 'react-bootstrap'
import FileUploadSports from './FileUploadSports';

import axios from 'axios'
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop,faPlus,faBaseball } from '@fortawesome/free-solid-svg-icons'


export default function Sports() {
    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [show, setShow] = useState(false);
    const [record,setRecord]=useState({})




    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        {
            name: '',
            selector: row => <Button variant="secondary" onClick={()=>showModal(row.SportID)}><FontAwesomeIcon icon={faEdit}/></Button>,
            sortable: true,
            maxWidth:'5px'
        },

        {
            name: 'Όνομα',
            selector: row =>  row.SportName,
            sortable: true
        },
  
        // {
        //     name: 'Default Γλώσσα',
        //     selector: row =>  row.LangName,
        //     sortable: true
        // },  
            
    ]
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        loadData()
    },[])




      const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/sports',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
           // console.log(res.data.results) 
          setData(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }



      const showModal=(id)=>{
        if (id==0){
            setRecord({ModalTitle:'ΝΕΑ ΕΓΓΡΑΦΗ', id:0,Title:''})
            setShow(true)
        }else{
            const recObj=data.filter(item=>item.SportID==id)[0]
            
            setRecord({ModalTitle:recObj.SportName, id:recObj.SportID,Title:recObj.SportName})
            setShow(true)            
        }

      }

      const save=(e)=>{
        e.preventDefault()
        axios.post(process.env.REACT_APP_API_SERVER+'admin/sports',record,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                }            
            })
          .then(res=>{
            if (res.data.err==0){
                loadData()
            }
            setShow(false)
            alert(res.data.msg)
        })
      }

      const deleteRecord=()=>{
        if (window.confirm('ΔΙΑΓΡΑΦΗ!!!! ΣΙΓΟΥΡΑ???')){
            axios.delete(process.env.REACT_APP_API_SERVER+'admin/sports/'+record.id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                    }            
                })
              .then(res=>{
                if (res.data.err==0){
                    loadData()
                }
                setShow(false)
                alert(res.data.msg)
            })
        }
      }

    return (
        <>
            {isLoading &&
            <Alert variant='warning'>Loading....Please Wait</Alert>

            }

            {!isLoading &&
            <>
            <Alert variant="secondary">
                <table width="100%">
                    <tr>
                        <td><h5>Αθλήματα</h5></td>
                        <td align="right">
                        <Button variant="dark" onClick={()=>showModal(0)}><FontAwesomeIcon icon={faPlus}/></Button>
                        </td>
                        <td width="10"></td>
                    </tr>
                </table>
                    
            </Alert>




                <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                />            
            
            </>
            }

                <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                <Modal.Title>
                    {record.ModalTitle}
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                <Form onSubmit={save}>

                        <>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Τίτλος</Form.Label>
                            <Form.Control type="text" required value={record.Title} onChange={(e)=>setRecord({...record,Title:e.target.value})}/>
                        </Form.Group>
                        <br/>




                        <Row><Col></Col>
                        <Col md="auto">

                            {record.id>0 &&
                            <>
                            <Button  variant="danger" style={{width:"150px"}} onClick={deleteRecord}>
                                Διαγραφή
                            </Button>
                            &nbsp; 
                            </>
                            }

                            <Button  variant="success" type="submit" style={{width:"150px"}}>
                                Αποθήκευση
                            </Button>                      
                        </Col>
                      </Row>                        
                        </>
                   </Form> 

                   {record.id>0 &&
                        <>
                        <hr/>
                        <FileUploadSports  id={record.id} type="3" />
                        </>
                    }
                             
                </Modal.Body>

            </Modal>   
        </>
    )
}
