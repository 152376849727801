import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Accordion,Modal} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import {FaBookOpen} from 'react-icons/fa'
import ChallengeForm from './ChallengeForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop,faPlus,faBaseball,FaMap } from '@fortawesome/free-solid-svg-icons'
import Flag from 'react-world-flags'

const customStyles = {
    rows: {
        style: {
            minHeight: '25px',
            fontSize:'12px'
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor:'#55505c',
            color:'#fff'
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            flex: 1
        },
    },
};


export default function Countries() {
    
    const [data,setData]=useState([])
    const [show,setShow]=useState(false)
    const [modalTitle,setModalTitle]=useState('')
    const [record,setRecord]=useState({})
    const [isLoading,setIsLoading]=useState(false)
    const [lang,setLang]=useState([])




    const columns = [

        {
            name: '',
            selector: row => <Button style={{fontSize:'12px',textDecoration:'none',color:'gray'}} variant="link" onClick={()=>showForm(row.CountryID)}><FontAwesomeIcon icon={faEdit}/></Button>,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: '',
            selector: row => <Flag code={ row.CountryCode } height="20" width="20"/>  ,
            sortable: true
        },

      
        {
            name: 'COUNTRY NAME',
            selector: row => row.Country,
            sortable: true
        },
        {
            name: 'EU',
            selector: row => row.EU==1 ? 'YES' : 'NO',
            sortable: true
        },
        {
            name: 'VAT',
            selector: row => row.VAT,
            sortable: true
        }, 
        {
            name: 'VAT FOR COMPANIES',
            selector: row => row.VATFLAG==1 ? 'YES' : 'NO',
            sortable: true
        }, 
        {
            name: 'VAT FOR INDIVIDUALS',
            selector: row => row.VATFLAG2==1 ? 'YES' : 'NO',
            sortable: true
        },         
        
        {
            name: 'COUNTRY CODE',
            selector: row => row.VIESCode,
            sortable: true
        },                   
    ];


    useEffect(()=>{
        loadData()
    },[])


    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/countries',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                }            
            })
          .then(res=>{
            console.log(res.data)
            setData(res.data)
            setIsLoading(false)
            loadLang()
          })        
    }


    const loadLang=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/lang',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
           // console.log(res.data.results) 
           setLang(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }


    const showForm=(id)=>{
        if (id==0){
            setModalTitle('New Country')
            setRecord({
                CountryID:0,
                EU:0,
                VATFLAG:0,
                VAT:0
            })
            
        }else{
            setRecord(data.filter(item=>item.CountryID==id)[0])
            setModalTitle(data.filter(item=>item.CountryID==id)[0].Country)
        }
        setShow(true)
    }

    const handleClose=()=>{
        setShow(false)
    }

    const save=(e)=>{
        e.preventDefault()
        console.log(record)
        axios.post(process.env.REACT_APP_API_SERVER+'admin/countries',record,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
          .then(res=>{
              if (res.data.err==0){
                loadData()
                setShow(false)
              }else{
                  alert("An error has occured, please try again")
              }
    
          }).catch(err=>{
            alert("An error has occured, please try again")
          }) 

    }

    




    const deleteRecord=()=>{
        if (window.confirm('DELETE '+record.Country+'?')){
  
  
          axios.delete(process.env.REACT_APP_API_SERVER+'admin/countries/'+record.CountryID,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                  }            
            })
            .then(res=>{
                if (res.data.err==0){
                    loadData()
                    setShow(false)
                }else{
                    alert("An error has occured, please try again")
                }
    
            }).catch(err=>{
                console.log(err)
            })  
  
        }
  
    }

    const dataList=data.map(item=>{
        return(
            <tr key={item.CountryCodeHash}>
            <td><Button variant="link" onClick={()=>showForm(item.CountryID)}>{item.Country}</Button></td>
            <td>{item.EU==1 ? 'YES' : 'NO'}</td>
            <td>{item.VAT}</td>
            <td>{item.VATFLAG==1 ? 'YES' : 'NO'}</td>
            <td>{item.VIESCode}</td>
          </tr>            
        )
    })

    const langList=lang.map(item=>{
        return(
            <option value={item.LangID}>{item.LangName}</option>
        )
    })

    return (    

        <Container fluid>
            <Alert variant='secondary'>
            
                <Row>
                <Col md="auto">
                <h4><FaBookOpen/>&nbsp;&nbsp;Χώρες</h4>
                </Col>
                <Col></Col>
                <Col md="auto">
                <Button  variant="dark" onClick={()=>showForm(0)}>Προσθήκη Χώρας</Button>
                </Col>
              </Row>


            </Alert>
            {isLoading &&
                <Alert variant='warning'>...Loading, please wait.</Alert>
            }
            {!isLoading &&

                <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                />



        }

        <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                <Modal.Title>
                    {modalTitle}
                    {record.CountryID>0 &&
                    <>
                    &nbsp;
                    <Flag code={ record.CountryCode } height="20"/>
                    </>
                    }
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                <Form onSubmit={save}>

                        <>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Country Name</Form.Label>
                            <Form.Control type="text" required value={record.Country} onChange={(e)=>setRecord({...record,Country:e.target.value})}/>
                        </Form.Group>
                        <br/>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Country Code</Form.Label>
                            <Form.Control type="text" required value={record.CountryCode} onChange={(e)=>setRecord({...record,CountryCode:e.target.value})}/>
                        </Form.Group>
                        <br/>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Language</Form.Label>
                            <Form.Select aria-label="Default select example" required value={record.LangID} onChange={(e)=>setRecord({...record,LangID:e.target.value})}>
                            <option value=''>[SELECT LANGUAGE]</option>
                            {langList}
                            </Form.Select>
                        </Form.Group>
                        <br/>



                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>VIES Code</Form.Label>
                            <Form.Control type="text" required value={record.VIESCode} onChange={(e)=>setRecord({...record,VIESCode:e.target.value})}/>
                        </Form.Group>
                        <br/>                          
                        <Form.Check 
                        type="checkbox"
                        label="EU Country"
                        checked={record.EU==1}
                        onChange={(e)=>setRecord({...record,EU:e.target.checked ? 1 : 0})}
                        />
                        <br/><br/>     
                        <Form.Check 
                        type="checkbox"
                        label="ADD VAT TO COMPANIES"
                        checked={record.VATFLAG==1}
                        onChange={(e)=>setRecord({...record,VATFLAG:e.target.checked ? 1 : 0})}
                        />

                        <br/>  

                        <Form.Check 
                        type="checkbox"
                        label="ADD VAT TO INDIVIDUALS       "
                        checked={record.VATFLAG2==1}
                        onChange={(e)=>setRecord({...record,VATFLAG2:e.target.checked ? 1 : 0})}
                        />
                        <br/>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>VAT Value %</Form.Label>
                            <Form.Control type="number" min={0} step={1} required value={record.VAT} onChange={(e)=>setRecord({...record,VAT:e.target.value})}/>
                        </Form.Group>
                        <br/>  


                        <Row><Col></Col>
                        <Col md="auto">

                            {record.CountryID>0 &&
                            <>
                            <Button   style={{fontWeight:'bold',width:"150px",backgroundColor:'#f7996e',color:'#55505c',borderColor:'#f7996e'}} onClick={deleteRecord}>
                                DELETE
                            </Button>
                            &nbsp; 
                            </>
                            }
                            <Button  style={{fontWeight:'bold',width:"150px",backgroundColor:'#957d95',color:'#ffffff',borderColor:'#957d95'}} onClick={handleClose}>
                                CANCEL
                            </Button>
                            &nbsp; 
                            <Button   type="submit"  style={{fontWeight:'bold',width:"150px",backgroundColor:'#94c9a9',color:'#55505c',borderColor:'#94c9a9'}} >
                                SAVE
                            </Button>                      
                        </Col>
                      </Row>  

                        </>
                </Form>
                        
                </Modal.Body>

            </Modal>   

        </Container>        

    )
}
