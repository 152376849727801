import React, {Fragment,useState,useEffect} from 'react'


export default function Challenge() {

  return (
    <>
    <div>Challenge</div>
    <input type="file"/>
    </>
  )
}
