import React from 'react'
import '../css/App.css'

export default function DashboardFooter() {
    return (
      <>
      <br/><br/><br/> <br/><br/><br/>
        <div className="footer">
        123 High 2024
      </div>
      </>
    )
}
