import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Tabs,Tab} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import axios from 'axios'
import ChallengeLangForm from './ChallengeLangForm'

export default function ChallengeLang(props) {

  const [languages,setlanguages]=useState([])
  const [data,setData]=useState([])
  const [isLoading,setIsLoading]=useState(false)

  useEffect(()=>{
    loadData()
  },[])

  const loadData=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'admin/lang',{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
          }            
      })
    .then(res=>{
     // console.log(res.data.results) 
      setData(res.data.results)
      setIsLoading(false)
      
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    })          
  }

  const languagesList=data.map(item=>{
    return(
      <Tab eventKey={item.LangID} key={item.LangID}  title={item.LangName}>
          <ChallengeLangForm id={props.id} LangID={item.UUID}/>

      </Tab>
    )
  })
  return (
    <>
        <Alert variant='secondary'>Γλώσσες {props.IsChallenge==1 ? 'Challenge' : 'Άρθρου' }</Alert>


        <Tabs >
          {languagesList}
        </Tabs>
    </>
  )
}

