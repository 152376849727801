import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Modal} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import {FaBookOpen} from 'react-icons/fa'
import ChallengeSelectVideo from './ChallengeSelectVideo';
import ReactPlayer from 'react-player'

export default function ChallengeVideos(props) {

    const [form,setForm]=useState(false)
    const [id,setId]=useState(0)
    const [isLoading,setIsLoading]=useState(true)
    const [data,setData]=useState([])
    const [show, setShow] = useState(false);
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        {
            name: '',
            selector: row => row.VideoID,
            sortable: true
        },

        {
            name: '',
            selector: row =>  {
                return(
                    <ReactPlayer 
                    url={`https://5f06d0924ebcd.streamlock.net:444/mlg/${row.VideoID}.smil/playlist.m3u8`}
                    controls={true}
                    light={process.env.REACT_APP_VIDEOSERVER_API+''+row.VideoID+'.jpg'}
                    width='300px'
                    height='190px'
                    />                    
                )
            },
            sortable: true
        },
        {
            name: '',
            selector: row => <Button variant="danger" onClick={()=>del(row.VideoID)}>X</Button>,
            sortable: true
        }
            
    ]


    useEffect(()=>{
        setIsLoading(true)
        loadData()
    },[])


    const del=(VideoID)=>{
        if (window.confirm('ΔΙΑΓΡΑΦΗ! Σίγουρα?')){
            axios.get(process.env.REACT_APP_API_SERVER+'admin/challenges/challengevideosdelete/'+props.id+'/'+VideoID,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                    }            
                })
              .then(res=>{
                
                if (res.data.err==0){
                    loadData()
                }else{
                    alert('Υπήρξε ένα πρόβλημα, παρακαλώ προσπαθήστε ξανά')
                }
                
                
              }).catch(err=>{
                  setIsLoading(false)
                  alert('Υπήρξε ένα πρόβλημα, παρακαλώ προσπαθήστε ξανά')
              })  
        }
        
    }

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/challenges/challengevideos/'+props.id,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
          
          setData(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }






    return (
        <>
        <Alert variant='secondary'>{props.IsChallenge==1 ? 'Challenge' : 'Άρθρου' } Videos</Alert>
            <p ><Button variant="success" onClick={()=>setShow(true)} >Προσθήκη Βίντεο</Button></p>
            <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
            />   






            <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
                <Modal.Header closeButton>
                <Modal.Title>
                    Επιλογή Βίντεο
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                        <ChallengeSelectVideo ChallengeID={props.id} loadData={loadData} handleClose={handleClose}/>
                </Modal.Body>

            </Modal> 


        </>
    )
}
