import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import axios from 'axios'
import {FaBookOpen} from 'react-icons/fa'
import ReactPlayer from 'react-player'
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import FileUpload from './FileUpload'
// import ChallengeLang from './ChallengeLang'
// import ChallengeVideos from './ChallengeVideos'
import UserChallenges from './UserChallenges'



export default function ChallengeFormReview(props) {

  const [record,setRecord]=useState({})
  const [isLoading,setIsLoading]=useState(false)
  const [descr,setDescr]=useState('')
  
useEffect(()=>{
 // console.log(props.item)
},[])


  const save=(e)=>{
  //   e.preventDefault()

  //   const obj={...record}
  //   axios.post(process.env.REACT_APP_API_SERVER+'admin/challenges',obj,{
  //     headers: {
  //         'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
  //         }            
  //     })
  //   .then(res=>{
  //     alert(res.data.msg)
  //     props.showForm(0,false)
  //   }).catch(err=>{
  //     alert('Υπήρξε ένα πρόβλημα, παρακαλώ προσπαθήστε ξανά')
  //   })
  // }

  // const chDescr=(e,editor)=>{
  //   var xDescr=editor.getData()

  //  setDescr(xDescr)
}

const activate=(IsPublished)=>{
   if (window.confirm('ΔΗΜΟΣΙΕΥΣΗ CHALLENGE!!!! ΣΙΓΟΥΡΑ???')){

    const obj={
      ChallengeID:props.item.ChallengeID,
      UserID:props.item.UserID,
      IsPublished:IsPublished
    }
    console.log(obj)
      axios.post(process.env.REACT_APP_API_SERVER+'admin/dashboard/publishusrchallenge',obj,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
          if (res.data.err==0){
              props.loadData()
             props.showForm(0,false)
          }else{
            alert(res.data.msg)
          }

          
      })
   }
}

  return (
    <>
    <br/><br/>
      {isLoading &&
      <Alert variant="warning">Loading...</Alert>
      
      }

      {!isLoading &&
      
        <>
           <h4>{props.item.Title} </h4> 
           <br/>
           {props.item.FirstName} {props.item.LastName}
            <hr/>     
            <ReactPlayer 
            url={`https://5f06d0924ebcd.streamlock.net:444/mlg/${props.item.FileID}.smil/playlist.m3u8`}
            controls={true}
            light={process.env.REACT_APP_VIDEOSERVER_API+''+props.item.FileID+'.jpg'}
            width='600px'
            height='380px'
            />
            <br/>
          <Button variant='success' onClick={()=>activate(1)}>PUBLISH</Button>

        </>
      }



    </>

  )
}
