import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Modal} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import axios from 'axios'
import DataTable from 'react-data-table-component';
import {FaBookOpen} from 'react-icons/fa'
import moment from 'moment'
import ReactPlayer from 'react-player'

export default function ChallengeUsers(props) {
    const [isLoading,setIsLoading]=useState(true)
    const [data,setData]=useState([])
    const [form,setForm]=useState(false)
    const [item,setItem]=useState({FileID:''})
    const [show, setShow] = useState(false);

    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },


        {
            name: 'Χρήστης',
            selector: row => <Link to="#" onClick={()=>{showForm(row,true)}}>{row.FirstName} {row.LastName}</Link>,
            sortable: true
        },

       

        {
            name: 'Challenge',
            selector: row =>  row.Title,
            sortable: true
        }, 

        {
          name: 'Δημοσίευση',
          selector: row =>  moment(row.DateCreated).format('DD/MM/YYYY'),
          sortable: true
      }, 

      {
        name: 'Is Pblished',
        selector: row =>  row.IsPublished,
        sortable: true
    }, 

    //   {
    //     name: '',
    //     selector: row => <img width="200" src={process.env.REACT_APP_VIDEOSERVER_API+''+row.FileID+'.jpg'}/>,
    //     sortable: true
    // }, 


      
    ]


    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/users/userschallenges/'+props.id,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
         console.log(res.data.results)   
          setData(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }

      const showForm=(row,a)=>{
        setShow(true)
        setItem(row)
        console.log(item)
    }

    const handleClose=()=>{
        setShow(false)
    }



    const activate=(IsPublished)=>{
        if (window.confirm('ΔΗΜΟΣΙΕΥΣΗ CHALLENGE!!!! ΣΙΓΟΥΡΑ???')){
     
         const obj={
           ChallengeID:item.ChallengeID,
           UserID:item.UserID,
           IsPublished:IsPublished
         }
        // console.log(obj)
           axios.post(process.env.REACT_APP_API_SERVER+'admin/dashboard/publishusrchallenge',obj,{
               headers: {
                   'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                   }            
               })
             .then(res=>{
               if (res.data.err==0){
                   loadData()
                    setShow(false)
               }else{
                 alert(res.data.msg)
               }
     
               
           })
        }
     }


    
  return (
    <>
        <Alert variant='secondary'> User Posts </Alert>

        <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                />  



                <Modal show={show} onHide={handleClose} backdrop="static" size='lg'>
                <Modal.Header closeButton>
                <Modal.Title>
                    {item.Title}
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>


                <ReactPlayer 
                    url={`https://5f06d0924ebcd.streamlock.net:444/mlg/${item.FileID}.smil/playlist.m3u8`}
                    controls={true}
                    light={process.env.REACT_APP_VIDEOSERVER_API+''+item.FileID+'.jpg'}
                    width='600px'
                    height='380px'
                />


                    <br/>
                    {item.IsPublished==1 &&
                        <Button variant='warning' onClick={()=>activate(0)}>UNPUBLISH</Button>
                    }

                    {item.IsPublished==0 &&
                        <Button variant='success' onClick={()=>activate(1)}>PUBLISH</Button>
                    }
                    
                </Modal.Body>

            </Modal> 

    </>
  )
}
