import React, {Fragment,useState,useEffect} from 'react'

import axios from 'axios'


const FileUpload = (props) => {

    const [file,setFile]=useState('')
    const [fileName,setFileName]=useState('Please select an MP4 file')
    const [upLoading, setUpLoading]=useState('none')
    const [uploadPercentage, setUploadPercentage]=useState(0)
    const [url,setUrl]=useState('')
    const [title,setTitle]=useState('')


    useEffect(() => {
        if (props.type==1){
            setUrl(process.env.REACT_APP_VIDEOSERVER_API+'upload')
            setFileName('Please select an MP4 file')
            setTitle('Upload Video (.MP4 format only)')
        }

        if (props.type==2){
            setUrl(process.env.REACT_APP_API_SERVER+'uploadtrainerphoto.php?id='+props.id)
            setFileName('Please select an image file')
            setTitle('Upload Trainer Photo.')
        }

        if (props.type==3){
            setUrl(process.env.REACT_APP_API_SERVER+'uploadprogramphoto.php?id='+props.id)
            setFileName('Please select an image file')
            setTitle('Upload Program Photo.')
        }
        if (props.type==4){
            setUrl(process.env.REACT_APP_API_SERVER+'uploadprogramcategoryphoto.php?id='+props.id)
            setFileName('Please select an image file')
            setTitle('Upload Program Category Photo.')
        }

        if (props.type==5){
            setUrl(process.env.REACT_APP_API_SERVER+'uploadvideophoto.php?id='+props.id)
            setFileName('Please select an image file')
            setTitle('Upload Video Photo.')
        }

        //console.log(props.type)
    }, [props.type])

    const fileChanged=e=>{

        try{

            if (props.type==1){
                if (e.target.files[0].type=='video/mp4'){
                    setFile(e.target.files[0])
                    setFileName(e.target.files[0].name)
                }else{
                    alert('Please Select MP4 file')
                }
            }

            if (props.type==2 || props.type==3 || props.type==4 || props.type==5){

                if (e.target.files[0].type.indexOf('image/')>-1){
                    setFile(e.target.files[0])
                    setFileName(e.target.files[0].name)
                }else{
                    alert('Please Select an IMAGE file')
                }
            }            
            
        }catch{

        }

    }

    const uploadFile=async e=>{
        e.preventDefault()
        console.log(url)
        const formData=new FormData()
        formData.append('file',file)
        formData.append('token',process.env.REACT_APP_API_TOKEN)
        formData.append('app',process.env.REACT_APP_NAME)
        try{
            setUpLoading('block')
            axios.post(url,formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                },
                onUploadProgress: ProgressEvent=>{
                    setUploadPercentage(parseInt(Math.round(ProgressEvent.loaded*100/ProgressEvent.total)))
                }
            }).then(res=>{
                setUpLoading('none')
                document.getElementById('customFile').value=null
                
                setUploadPercentage(0)
                //console.log(res.data)
                if (res.data.err==0){
                    const newVideo={
                        id:res.data.id,
                        status:0,
                        duration:0
                    }
                    props.addNewVideo(newVideo)
                }else{
                    alert('An Error has occured! Please Try Again')
                }
                //props.refreshVideos()
            }).catch(err=>{
                console.log(err)
            })



            
        }catch(err){

        }
    }
    return (
        
        <Fragment>
            <div className="alert alert-success" role="alert">
            {title}
            </div>
            <form onSubmit={uploadFile}>
            <div className="custom-file mt-4">
                <input required onChange={fileChanged} type="file" className="custom-file-input" id="customFile"/>
                <label className="custom-file-label" htmlFor="customFile">
                    {fileName}
                </label>
            </div>
            <br/><br/>                
            <button type="submit" disabled={upLoading=='block'} className="btn btn-success">Upload {props.type==1 ? 'Video' : 'Photo'}</button>
            </form>
            <br/>
            <div className="alert alert-danger" role="alert" style={{display:`${upLoading}`}}>
                Uploading! Please Wait!
            </div>
            <div className="progress">
            <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width:`${uploadPercentage}%`}}>{uploadPercentage}%</div>
            </div> 
        </Fragment>
    )


}

export default FileUpload;