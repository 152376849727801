import React, {useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link,NavLink} from 'react-router-dom'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav} from 'react-bootstrap';
import { FaUserClock,FaProductHunt,FaShopify,FaList, FaMap,FaGlobe,FaFootballBall,FaNewspaper,FaFace,FaKey,FaTextHeight,FaBan,FaBell,FaAdversal} from 'react-icons/fa'
import { FaFaceGrinStars, FaShop } from "react-icons/fa6";
import logo from '../logo.png'
import axios from 'axios'



export default function DashboardNav() {

    const {userName}=useContext(UserContext)
    const {setIsAuth} = useContext(UserContext)
    const {userStatus}= useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)

    const {modules,setModules}= useContext(UserContext)

    useEffect(()=>{

    },[apiToken]) 

    
    
    

    const logout=()=>{
        setIsAuth(false)
        sessionStorage.setItem("isAuth",0)


    }

    return (
            <>

                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
                 
                <Navbar.Brand>
                    <Link to="/dashboard"><img src={logo} style={{width:30, marginTop: -7}} /></Link>
                    &nbsp;
                    <span style={{color:"#fcb715"}}>123 High</span> {userStatus==1000 && <span style={{color:"#ff742e"}}>Administrator</span>}
                    &nbsp;&nbsp;
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">

                    <NavDropdown title="Περιεχόμενο" id="collasible-nav-dropdown">
                            <NavDropdown.Item>
                                <Link to ='/challenges' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaNewspaper/>
                                    &nbsp;
                                    Περιεχόμενο
                                </Link>
                            </NavDropdown.Item>


                            <NavDropdown.Item>
                                <Link to ='/sponsors' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaAdversal/>
                                    &nbsp;
                                    Sponsors
                                </Link>
                            </NavDropdown.Item>


                            <NavDropdown.Item>
                                <Link to ='/contentreports' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaBan/>
                                    &nbsp;
                                    Content Reports
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to ='/pushnotifications' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaBell/>
                                    &nbsp;
                                    Push Notifications
                                </Link>
                            </NavDropdown.Item>


{/* 
                            <NavDropdown.Item>
                                <Link to ='/categories' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaNetworkWired/>
                                    &nbsp;
                                    Κατηγορίες Προγραμμάτων
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />

                            <NavDropdown.Item>
                                <Link to ='/programs' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaBookOpen/>
                                    &nbsp;
                                    Προγράμματα
                                </Link>
                            </NavDropdown.Item> */}
                        </NavDropdown>

                        <NavDropdown title="Users" id="collasible-nav-dropdown">
                            <NavDropdown.Item>
                                <Link to ='/users' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaUserClock/>
                                    &nbsp;
                                    Users
                                </Link>
                            </NavDropdown.Item>
{/* 
                            <NavDropdown.Item>
                                <Link to ='/categories' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaNetworkWired/>
                                    &nbsp;
                                    Κατηγορίες Προγραμμάτων
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />

                            <NavDropdown.Item>
                                <Link to ='/programs' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaBookOpen/>
                                    &nbsp;
                                    Προγράμματα
                                </Link>
                            </NavDropdown.Item> */}
                        </NavDropdown>

                        <NavDropdown title="Videos" id="collasible-nav-dropdown">
                            {/* <NavDropdown.Item>
                                <Link to ='/categories' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaNetworkWired/>
                                    &nbsp;
                                    Κατηγορίες Βίντεο 
                                </Link>
                            </NavDropdown.Item> */}


                            <NavDropdown.Item>
                                <Link to ='/videos' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaList/>
                                    &nbsp;
                                    Λίστα Βίντεο
                                </Link>
                            </NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Παράμετροι" id="collasible-nav-dropdown">
                            <NavDropdown.Item>
                                <Link to ='/lang' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaGlobe/>
                                    &nbsp;
                                    Γλώσσες Περιεχομένου
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to ='/sports' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaFootballBall/>
                                    &nbsp;
                                    Αθλήματα
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to ='/countries' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaMap/>
                                    &nbsp;
                                    Χώρες
                                </Link>
                            </NavDropdown.Item>

                         

                            <NavDropdown.Item>
                                <Link to ='/reactions' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaFaceGrinStars/>
                                    &nbsp;
                                    Reactions
                                </Link>
                            </NavDropdown.Item>                            
                        </NavDropdown>




                    </Nav>


                    <Nav>
                    <NavDropdown title='EShop' id="collasible-nav-dropdown">
                        <NavDropdown.Item>
                                <Link to ='/eshopsettings' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaShop/>
                                    &nbsp;
                                    Settings
                                </Link>
                            </NavDropdown.Item>  

                            <NavDropdown.Item>
                                <Link to ='/eshopproducts' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaProductHunt/>
                                    &nbsp;
                                    Products
                                </Link>
                            </NavDropdown.Item>                          

                        </NavDropdown>
                    </Nav>



                    <Nav>
                    <NavDropdown title='Admin Settings' id="collasible-nav-dropdown">


                            <NavDropdown.Item>
                                <Link to ='/adminusers' style={{color:"#212529",textDecoration:"none"}}>
                                    <FaKey/>
                                    &nbsp;
                                    Admin Users
                                </Link>
                            </NavDropdown.Item>                          
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#" onClick={logout}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                </Navbar>
            </>
    )
}
