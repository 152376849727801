import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Tabs,Tab} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import axios from 'axios'

export default function ChallengeLangForm(props) {
    const [record,setRecord]=useState({id:0,Title:'',Descr:''})
    const [isLoading,setIsLoading]=useState(false)

    useEffect(()=>{
        loadData()
    },[])

    const save=(e)=>{
        e.preventDefault()
        const obj={...record,LangID:props.LangID,ChallengID:props.id}
        axios.post(process.env.REACT_APP_API_SERVER+'admin/challenges/challengelang',obj,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                }            
            })
          .then(res=>{
            //console.log(res.data)
            alert(res.data.msg)
            loadData()
          }).catch(err=>{
            alert('Υπήρξε ένα πρόβλημα, παρακαλώ προσπαθήστε ξανά')
          })
    }

    const loadData=()=>{
        axios.get(process.env.REACT_APP_API_SERVER+'admin/challenges/challengelang/'+props.id+'/'+props.LangID,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                }            
            })
          .then(res=>{

            setRecord(res.data.results)

            //console.log(res.data.results)
            setIsLoading(false)
            
          }).catch(err=>{
              setIsLoading(false)
 
          })
    }

    const del=()=>{
        if (window.confirm('ΔΙΑΓΡΑΦΗ, ΣΙΓΟΥΡΑ?')){
            axios.get(process.env.REACT_APP_API_SERVER+'admin/challenges/challengelangdel/'+record.UUID,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                    }            
                })
              .then(res=>{
                loadData()
                alert(res.data.msg)
                
                
              }).catch(err=>{
                  setIsLoading(false)
     
              })
        }

    }


  return (
    <>

        <br/>
        <Form onSubmit={save}>
        
        <Form.Group controlId="formBasicEmail">
            <Form.Label><b>Τίτλος Challenge</b></Form.Label>
            <Form.Control type="text"  required value={record.Title} onChange={(e)=>setRecord({...record,Title:e.target.value})}/>
        </Form.Group> 

        <br/>
        <Form.Group>
        <Form.Label><b>Περιγραφή</b></Form.Label>
        <Form.Control as="textarea" rows={3} value={record.Descr} onChange={(e)=>setRecord({...record,Descr:e.target.value})}/>
        </Form.Group>

        <br/><br/>
        <p align="right">

            {record.id!=0 &&
            <>
            <Button  variant="danger" style={{width:"150px"}} onClick={del}>
                Διαγραφή
            </Button> 
            &nbsp;&nbsp;   
            </>          
            }

            <Button  variant="dark" type="submit" style={{width:"150px"}}>
                Αποθήκευση
            </Button>
        </p>


        </Form>
    </>
  )
}
