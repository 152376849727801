import React,{useState,useEffect,useContext,createContext} from 'react'
import {Route,Routes,Navigate,useNavigate,BrowserRouter,Switch,HashRouter} from 'react-router-dom'
import {UserContext} from './context/UserContext'
import axios from 'axios'

import Login from './Login'
import Videos from './Videos'
import ProtectedRoute from './protected/ProtectedRoute'
import Dashboard from './protected/Dashboard'
import Challenges from './protected/Challenges'
import Lang from './protected/Lang'
import Users from './protected/Users'
import Sports from './protected/Sports'
import Countries from './protected/Countries'
import AdminUsers from './protected/AdminUsers'
import ContentReports from './protected/ContentReports'
import PushNotifications from './protected/PushNotifications'
import Sponsors from './protected/Sponsors'
import EShopProducts from './protected/EShopProducts'
import EShopSettings from './protected/EShopSettings'

//---------------------Mobile------------------
import Home from './mobile/Home'
import MobChallenges from './mobile/Challenges'
import MobChallenge from './mobile/Challenge'
import MobSignUp from './mobile/SignUp'

export default function App() {


  const [isAuth,setIsAuth]=useState(false)
  const [userToken,setUserToken]=useState(null)
  const [apiToken,setApiToken]=useState(null)
  const [userStatus,setUserStatus]=useState(0)
  const [userName,setUserName]=useState(null)
  const [modules,setModules]=useState([])


  return (
    <UserContext.Provider value={{isAuth,setIsAuth,userToken,setUserToken,apiToken,setApiToken,userStatus,setUserStatus,userName,setUserName,modules,setModules}}>
    <HashRouter basename={'/'}>
    <Routes>
        <Route exact path="/" element={<Login/>}/>
        <Route exact path="/login" element={<Login/>}/>
        <Route exact path="/dashboard" element={<ProtectedRoute Component={Dashboard}/>}/>
        <Route exact path="/videos" element={<ProtectedRoute Component={Videos}/>}/>
        <Route exact path="/challenges" element={<ProtectedRoute Component={Challenges}/>}/>
        <Route exact path="/sponsors" element={<ProtectedRoute Component={Sponsors}/>}/>
        <Route exact path="/lang" element={<ProtectedRoute Component={Lang}/>}/>
        <Route exact path="/sports" element={<ProtectedRoute Component={Sports}/>}/>
        <Route exact path="/users" element={<ProtectedRoute Component={Users}/>}/>
        <Route exact path="/countries" element={<ProtectedRoute Component={Countries}/>}/>
        <Route exact path="/adminusers" element={<ProtectedRoute Component={AdminUsers}/>}/>
        <Route exact path="/contentreports" element={<ProtectedRoute Component={ContentReports}/>}/>
        <Route exact path="/pushnotifications" element={<ProtectedRoute Component={PushNotifications}/>}/>
        <Route exact path="/eshopproducts" element={<ProtectedRoute Component={EShopProducts}/>}/>
        <Route exact path="/eshopsettings" element={<ProtectedRoute Component={EShopSettings}/>}/>

        <Route exact path="/mobile/home/:uid" element={<Home/>}/>
        <Route exact path="/mobileshome/:uid" element={<Home/>}/>
        <Route exact path="/mobile/challenges/:uid" element={<MobChallenges/>}/>
        <Route exact path="/mobile/challengedetails/:uid/:cid" element={<MobChallenge/>}/>
        <Route exact path="/mobile/signup" element={<MobSignUp/>}/>

      </Routes>
    </HashRouter>
    </UserContext.Provider>
  )
}
