import React,{useRef,useState,useEffect,useContext} from 'react'

import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table} from 'react-bootstrap'
import FileUpload from './FileUpload'
import axios from 'axios'
import ReactPlayer from 'react-player'
import {FaList} from 'react-icons/fa'

export default function Videos() {
    
    const [videos,setVideos]=useState([])
    const [videos1,setVideos1]=useState([])
    const [isLoading,setIsLoading]=useState(true)
    const [fn,setFn]=useState(0)
    const [newVideo,setNewVideo]=useState('')

    const sesrchStr=useRef()

    useEffect(()=>{
        loadVideos()
    },[])

    const loadVideos=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_VIDEOSERVER_API+'upload/'+process.env.REACT_APP_NAME)
        .then(res=>{
            setVideos(res.data.sort((a,b)=>(a.Timestamp<b.Timestamp) ? 1 : -1))
            setVideos1(res.data.sort((a,b)=>(a.Timestamp<b.Timestamp) ? 1 : -1))
            setIsLoading(false)
        })
    }

    const renVideo=(vid,ffn)=>{

        if (ffn){
            const formData=new FormData()
            formData.append('fn',vid)
            formData.append('ffn',ffn)
            axios.post(process.env.REACT_APP_VIDEOSERVER_API+'vjson',formData,{}) 
            .then(res=>{
               if (res.data.err==0){
                    //loadVideos()
                    setFn(0)
               }
            })
        }

    }

    const chFrinedlyName=(e,vid)=>{
        var v=[...videos]
        v.forEach(item=>{
            if (item.id==vid){
                item.friendlyName=e.target.value
            }
        })

        setVideos(v)
    }

    const addNewVideo=(vidObj)=>{
        setVideos([...videos,vidObj].sort((a,b)=>(a.Timestamp<b.Timestamp) ? 1 : -1))
        
        setNewVideo(vidObj.id)

        var checkStatus=setInterval(function(){
            const url=process.env.REACT_APP_VIDEOSERVER_API+'upload/'+process.env.REACT_APP_NAME+'_'+vidObj.id
            axios.get(url)
            .then(res=>{
                if (res.data[0].status==1){
                    loadVideos()
                    setNewVideo('')
                    clearInterval(checkStatus)
                }
            })
        }, 3000);
    }

    const delVideo=(vid)=>{
       // alert(1)
        if (window.confirm("Θα διαγραφεί τελέιως το βίντεο!!!"+vid+" Σίγουρα???")){
            axios.delete(process.env.REACT_APP_VIDEOSERVER_API+'upload/'+vid)
            .then(res=>{
                const sid=0
                // const VideoFormData=new FormData()
                // VideoFormData.append('vid',vid)
                //alert(process.env.REACT_APP_API_SERVER+'admin/videos/'+vid)

                // VideoFormData.append('token',process.env.REACT_APP_API_TOKEN)
                axios.delete(process.env.REACT_APP_API_SERVER+'admin/videos/'+vid,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                    }            
                })
                .then(res=>{
                    if (res.data.err==0){
                        alert("Το βίντεο διαγράφηκε από όλα τα σημεία")
                    }else{
                        alert("Η ενημέρωση δεν έγινε.")
                    }
                })                
                
                var v=[...videos].filter(item=>item.id!=vid).sort((a,b)=>(a.Timestamp<b.Timestamp) ? 1 : -1)
                setVideos(v)         
            }).catch(err=>{
                alert(3)
                alert(err.message)
            })
        }
    }

    const searchVideos=()=>{
        var s=sesrchStr.current.value

        var videoList=[]
        if (s.length>0){
            videos.forEach(item=>{
                if (item.friendlyName){
                    if (item.id.indexOf(s)>-1 || item.friendlyName.indexOf(s)>-1){
                        videoList.push(item)
                    }  
                }
 
            })
        }else{
            videoList=[...videos1]
        }

        setVideos([...videoList].sort((a,b)=>(a.Timestamp<b.Timestamp) ? 1 : -1))
    }    

    const videosList=videos.map(item=>{
       if (item.id){
        var uplDate = new Date(parseInt(item.Timestamp))
        const itemDur=parseInt(item.duration)
        // console.log(item.Timestamp)
        // console.log(date)
        return(
            <tr key={item.id}>
                <td>
                    {item.id}

                    
                </td>
                <td>
                {!(newVideo==item.id) &&
                    <span>{uplDate.toString().split('GMT')[0]}</span>
                }
                </td>
                <td>
                    {item.friendlyName && fn!=item.id && 
                        <span>
                            <Link to="#" onClick={()=>{setFn(item.id)}}>
                            <b><font color="green">{item.friendlyName}</font></b>
                            </Link>
                        </span>
                    }

                    {!(newVideo==item.id) &&
                        <span>
                            <hr/>
                            <Link to="#" onClick={()=>{setFn(item.id)}}>
                            <font color="gray">Επεξεργασία</font>
                            </Link>
                        </span>
                    
                    }                       
                    <br/><br/>
                    {fn==item.id && 
                    <>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control 
                            type="text" 
                            placeholder="Φιλική Ονομασία" 
                            value={!item.friendlyName ? '' : item.friendlyName}
                            onChange={(e)=>chFrinedlyName(e,item.id)}
                            
                            
                        />
                    </Form.Group>
                    <p align="right">


                        <Button onClick={()=>{renVideo(item.id,item.friendlyName)}}  variant='primary'>Αποθήκευση</Button>
                        &nbsp;
                        <Button onClick={()=>setFn(0)} variant='secondary'>Άκυρο</Button>
                                                
                        &nbsp;                        
                        <Button onClick={()=>delVideo(item.id)} variant='danger'>Ολική Διαγραφή Βίντεο</Button>
                    </p> 
                    </>
                    }
                </td>
                <td>

                    {parseInt(itemDur/60)}:{itemDur-parseInt(itemDur/60)*60}
                </td>
                <td>
                 {newVideo==item.id &&
                 <h4><font color="green">Processing video, please wait...</font></h4>
                 
                 }

                 {newVideo!=item.id &&  
                 <> 
                {`https://5f06d0924ebcd.streamlock.net:444/mlg/${item.id}.smil/playlist.m3u8`}

                <ReactPlayer 
                url={`https://5f06d0924ebcd.streamlock.net:444/mlg/${item.id}.smil/playlist.m3u8`}
                controls={true}
                light={process.env.REACT_APP_VIDEOSERVER_API+''+item.id+'.jpg'}
                width='320px'
                height='200px'
                />
                </>
                 }

                </td>
            </tr>
        )
       }
    })




    return (
        <>
            <Alert variant="secondary"><h4><FaList/> Videos</h4></Alert>
                <Container fluid>
                <FileUpload addNewVideo={addNewVideo} type="1"/>

                <hr/>

                {isLoading &&
                    <h3>Loading, please wait....</h3>
                
                }

                {!isLoading &&
                <>
                <input onChange={searchVideos} type="text" className="form-control" ref={sesrchStr} placeholder="Search..."></input>
                <br/><br/>                

                <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Ημερομηνία που Ανέβηκε</th>
                    <th>Φιλική Ονομασία</th>
                    <th>Διάρκεια (min)</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {videosList}
                </tbody>
                </Table>
                </>
                }
                </Container>

               
        </>
        
    )
}
