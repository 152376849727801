import React, {Fragment,useState,useEffect} from 'react'

import axios from 'axios'
import { Button } from 'react-bootstrap'


const FileUploadSponsor = (props) => {

    const [file,setFile]=useState('')
    const [fileName,setFileName]=useState('Please select an MP4 file')
    const [upLoading, setUpLoading]=useState('none')
    const [uploadPercentage, setUploadPercentage]=useState(0)
    const [url,setUrl]=useState('')
    const [title,setTitle]=useState('')
    const [photo,setPhoto]=useState('')


    useEffect(() => {


        loadFile(props.id)


            setUrl(process.env.REACT_APP_API_SERVER+'admin/uploadsponsorimage')
            setFileName('Please select an image file')
            setTitle('Upload  Photo.')



        //console.log(props.type)
    }, [props.type])


    useEffect(()=>{
        loadFile(props.id)
    },[props.id])

    const fileChanged=e=>{

        try{



            

                if (e.target.files[0].type.indexOf('image/')>-1){
                    setFile(e.target.files[0])
                    setFileName(e.target.files[0].name)
                }else{
                    alert('Please Select an IMAGE file')
                }
           
            
        }catch{

        }

    }

    const uploadFile=async e=>{
        e.preventDefault()
      //console.log(file)
        const formData=new FormData()
        formData.append('file',file)
        formData.append('id',props.id)
        try{
            setUpLoading('block')
            axios.post(url,formData,{
                headers:{
                    'Content-Type':'multipart/form-data',
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                },
                onUploadProgress: ProgressEvent=>{
                    setUploadPercentage(parseInt(Math.round(ProgressEvent.loaded*100/ProgressEvent.total)))
                }
            }).then(res=>{
                setUpLoading('none')
                document.getElementById('customFile').value=null
                
                setUploadPercentage(0)

                if (res.data.err==0){
                    loadFile(props.id)
                    props.loadData(props.id)
                }else{
                    alert('An Error has occured! Please Try Again')
                }

            }).catch(err=>{
                console.log(err)
            })



            
        }catch(err){

        }
    }

    const loadFile=(id)=>{

            const t=Date.now()
            setPhoto(process.env.REACT_APP_API_SERVER+'admin/files/sponsor/'+id+'/'+t)



    }

    const delFile=()=>{
        if (window.confirm('Delete File?')){
            axios.delete(process.env.REACT_APP_API_SERVER+'admin/uploadsponsorimage/'+props.id,{
                headers:{
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                }
            }).then(res=>{
                props.loadData(props.id)
                loadFile(props.id)
            })
        }

    }

    return (
        
        <Fragment>

            <div className="alert alert-success" role="alert">
            {title}
            </div>
            <form onSubmit={uploadFile}>
            <div className="custom-file mt-4">
                <input required onChange={fileChanged} type="file" className="custom-file-input" id="customFile"/>
                <label className="custom-file-label" htmlFor="customFile">
                    {fileName}
                </label>
            </div>
            <br/><br/>                
            <button type="submit" disabled={upLoading=='block'} className="btn btn-success">Upload {props.type==1 || props.type==0 ? ' Main Photo' :  'Sponsor Logo'}</button>
            </form>
            <br/>
            <div className="alert alert-danger" role="alert" style={{display:`${upLoading}`}}>
                Uploading! Please Wait!
            </div>
            <div className="progress">
            <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width:`${uploadPercentage}%`}}>{uploadPercentage}%</div>
            </div> 
            <hr/>
           
          
           <>

           {props.record.Photo &&
            <>
            <img src={photo} width="200"/>
            &nbsp;&nbsp;
            <Button variant="danger" onClick={delFile}>X</Button>
            </>
           }
            </>
      
        </Fragment>
    )


}

export default FileUploadSponsor;