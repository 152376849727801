import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Modal} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import {FaBookOpen, FaUser,FaEdit} from 'react-icons/fa'
import ChallengeSelectVideo from './ChallengeSelectVideo';
import ReactPlayer from 'react-player'
import moment from 'moment'


export default function UserPosts(props) {
    const [form,setForm]=useState(false)
    const [id,setId]=useState(0)
    const [isLoading,setIsLoading]=useState(true)
    const [data,setData]=useState([])
    const [show, setShow] = useState(false)
    const [content,setContent]=useState({})
    const [reactions,setReactions]=useState([])
    const points=useRef()

    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        {
            name: '',
            selector: row =>  <Button variant="secondary" onClick={()=>{handleShow(row.id)}}><FaEdit/></Button>,
            sortable: true,
            maxWidth:'5px'
        },


        {
            name: '',
            selector: row => {

                if (row.Photo.length>0){
                    return(
                        <img width="50" src={process.env.REACT_APP_API_SERVER+'postfiles/'+row.ContentUUID+'/123'}class="challenge-post-photo show" alt="photo" />
                    )
                }else{
                    return(
                    <ReactPlayer 
                        url={`${process.env.REACT_APP_VIDEO_BASE_URL}${row.VID}.smil/playlist.m3u8`}
                        controls={true}
                        light={process.env.REACT_APP_VIDEOSERVER_API+''+row.VID+'.jpg'}
                        width='320px'
                        height='200px'
                        />                                    
                    )                    
                }

                
            },
            sortable: true
        },

        {
            name: 'Description',
            selector: (row,index) =>  row.Descr,
            sortable: true,

        },        
        {
            name: 'Ranking',
            selector: (row,index) =>  row.v,
            sortable: true,

        }, 
        {
            name: 'Date Created',
            selector: (row,index) =>  row.DateCreated,
            sortable: true,

        }
            
    ]


    const reactionColumns=[
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        {
            name: 'Person who reacted',
            selector: (row,index) =>  row.FirstName+ ' '+row.LastName ,
            sortable: true,

        }, 
        {
            name: '',
            selector: (row,index) => row.ReactUserID!='0' ? <img src={loadImage(row.ReactUserUUID)} width="60"/> : '',
            sortable: true,

        },        
      
        {
            name: 'Rank',
            selector: (row,index) =>  row.RankType,
            sortable: true,
            maxWidth:'5px'
        },      

        // {
        //     name: '',
        //     selector: (row,index) =>  <Button variant='warning' onClick={()=>deleteReaction(row)}>X</Button>,
        //     sortable: true,

        // } 
    ]


    const deleteReaction=(row)=>{
        console.log(row)
    }

    const handleShow=(id)=>{

        axios.get(process.env.REACT_APP_API_SERVER+'admin/users/posts/ranking/'+id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                }            
            })
          .then(res=>{
            setReactions(res.data.results)
            setIsLoading(false)
            
          }).catch(err=>{
              setIsLoading(false)
              console.log(err)
          }) 


        setContent(data.filter(item=>item.id==id)[0])
       // console.log(data.filter(item=>item.id==id)[0])
        setShow(true)
    }

    const handleClose=()=>{
        setShow(false)
    }


    const loadImage=(id)=>{
        const d=Date.now()
        //alert(`${process.env.REACT_APP_API_SERVER}files/${userID}/${d}`)
        return(`${process.env.REACT_APP_API_SERVER}files/${id}/${d}`)
      }

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/users/posts/'+props.id,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
          //console.log(res.data.results)
          setData(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }

      const deleteContent=(id)=>{
        if (window.confirm('DELETE CONTENT! Are you sure?')){
            axios.get(process.env.REACT_APP_API_SERVER+'admin/users/posts/delete/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                    }            
                })
              .then(res=>{
                if (res.data.err==0){
                    setShow(false)
                    loadData()
                }else{
                    alert('An error has occured')
                }
                
              }).catch(err=>{
                  console.log(err)
              }) 
        }
      }


      const addPoints=(e)=>{
        e.preventDefault()
        const obj={
            ChallengeID:content.id,
            ChUserID:content.PostedBy,
            ReactUserID:0,
            RankValue:1,
            RankType:points.current.value,
            PostType:0,
        }
        axios.post(process.env.REACT_APP_API_SERVER+'admin/users/posts/addpoints',obj,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                }            
            })
          .then(res=>{
            
            if (res.data.err==0){
                loadData()
                handleShow(content.id)
            }else{
                alert('An error has occured')
            }
            
          }).catch(err=>{
              console.log(err)
          }) 
      }

  return (
    <>
    
    <Alert variant='secondary'>User Posts</Alert>
            <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
            />   



            <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
                <Modal.Header closeButton>
                <Modal.Title>
                {content.Descr}<br/><br/>
                <b>Date Created: </b> {moment(content.DateCreated).format('DD/MM/YYYY')}

                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                {content.VideoID?.length>0 &&
                            <>



                            <ReactPlayer 
                                url={`${process.env.REACT_APP_VIDEO_BASE_URL}${content.VID}.smil/playlist.m3u8`}
                                controls={true}
                                light={process.env.REACT_APP_VIDEOSERVER_API+''+content.VID+'.jpg'}
                                width='320px'
                                height='200px'
                                />                                    
                            </>
                        }

                        {content.Photo?.length>0 &&
                        <>
                            <img width="300" src={process.env.REACT_APP_API_SERVER+'postfiles/'+content.ContentUUID+'/123'}class="challenge-post-photo show" alt="photo" />
                        </>
                        }  

                        <br/>
                        <Alert variant="success">
                            <Alert.Heading>Admin Points</Alert.Heading>

                            <p className="mb-0">
                            <Form onSubmit={addPoints}>

                            <Form.Control type="number" min={-100} step={1} max={100} ref={points} required placeholder='Enter Admin Points'/>
                                <br/>
                                <p align="right">
                                    <Button variant="success" type="submit">Add Points</Button>
                                </p>
                            </Form>
                            </p>
                            </Alert>


                    <DataTable
                        columns={reactionColumns}
                        data={reactions}
                        highlightOnHover
                        //expandableRows
                        //expandableRowsComponent={ExpandedComponent}
                        //pagination
                    />  

                    <hr/>
                    <p align="right">
                        <Button variant='danger' onClick={()=>deleteContent(content.id)}>DELETE THIS CONTENT</Button>
                    </p>


                </Modal.Body>

            </Modal>            
    </>
  )
}
