import React, {useState,useContext,createContext,useEffect,useRef}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Row,Col,Accordion} from 'react-bootstrap'
import FileUploadSports from './FileUploadSports';

import axios from 'axios'
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop,faPlus,faBaseball } from '@fortawesome/free-solid-svg-icons'


export default function EShopSettings() {
    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [show, setShow] = useState(false);
    const [record,setRecord]=useState({})




    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        {
            name: '',
            selector: row => <Button variant="secondary" onClick={()=>showModal(row.id)}><FontAwesomeIcon icon={faEdit}/></Button>,
            sortable: true,
            maxWidth:'5px'
        },

        {
            name: 'Stripe Key',
            selector: row =>  row.StripeKey,
            sortable: true
        },
        {
            name: 'EShop Url',
            selector: row =>  row.EShopUrl,
            sortable: true
        },
        // {
        //     name: 'Default Γλώσσα',
        //     selector: row =>  row.LangName,
        //     sortable: true
        // },  
            
    ]
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        loadData()
    },[])




      const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/eshopsettings',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{

          setData(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }



      const showModal=(id)=>{

            const recObj=data.filter(item=>item.id==id)[0]
            
            setRecord({...recObj,ModalTitle:''})
            setShow(true)            


      }

      const save=(e)=>{
        e.preventDefault()

        axios.post(process.env.REACT_APP_API_SERVER+'admin/eshopsettings',record,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                }            
            })
          .then(res=>{
            if (res.data.err==0){
                loadData()
                sessionStorage.setItem('StripeKey',record.StripeKey)
                sessionStorage.setItem('EShopUrl',record.EShopUrl)
            }
            setShow(false)
            alert(res.data.msg)
        })
      }

      const deleteRecord=()=>{
        if (window.confirm('ΔΙΑΓΡΑΦΗ!!!! ΣΙΓΟΥΡΑ???')){
            axios.delete(process.env.REACT_APP_API_SERVER+'admin/sports/'+record.id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                    }            
                })
              .then(res=>{
                if (res.data.err==0){
                    loadData()

                }
                setShow(false)
                alert(res.data.msg)
            })
        }
      }

    return (
        <>
            {isLoading &&
            <Alert variant='warning'>Loading....Please Wait</Alert>

            }

            {!isLoading &&
            <>
            <Alert variant="secondary">
                <table width="100%">
                    <tr>
                        <td><h5>EShop Settings</h5></td>
                        <td align="right">
                        {/* <Button disabled variant="dark" onClick={()=>showModal(0)}><FontAwesomeIcon icon={faPlus}/></Button> */}
                        </td>
                        <td width="10"></td>
                    </tr>
                </table>
                    
            </Alert>




                <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                />            
            
            </>
            }

                <Modal show={show} onHide={handleClose} backdrop="static" size='lg'>
                <Modal.Header closeButton>
                <Modal.Title>
                    {record.ModalTitle}
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                <Form onSubmit={save}>

                        <>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><b>Stripe Key</b></Form.Label>
                            <Form.Control type="text"  value={record.StripeKey} onChange={(e)=>setRecord({...record,StripeKey:e.target.value})}/>
                        </Form.Group>
                        <br/>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><b>EShop Url</b> (χωρίς / στο τέλος πχ. https://manager.onetwothreehigh.com)</Form.Label>
                            <Form.Control  type="text" required value={record.EShopUrl} onChange={(e)=>setRecord({...record,EShopUrl:e.target.value})}/>
                        </Form.Group>
                        <br/>



                        <Row><Col></Col>
                        <Col md="auto">

                            {/* {record.id>0 &&
                            <>
                            <Button  variant="danger" style={{width:"150px"}} onClick={deleteRecord}>
                                Διαγραφή
                            </Button>
                            &nbsp; 
                            </>
                            } */}

                            <Button disabled={record.PWD!=record.PWD2} variant="success" type="submit" style={{width:"150px"}}>
                                Αποθήκευση
                            </Button>                      
                        </Col>
                      </Row>                        
                        </>
                   </Form> 


                             
                </Modal.Body>

            </Modal>   
        </>
    )
}
