
import React, {useState,useContext,createContext,useEffect,useRef}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Row,Col,Accordion} from 'react-bootstrap'


import axios from 'axios'
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop,faPlus, faShoppingBasket,faShoppingCart} from '@fortawesome/free-solid-svg-icons'
import {FaBell} from 'react-icons/fa'

export default function PushNotifications() {
    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [show, setShow] = useState(false);
    const [record,setRecord]=useState({})




    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        {
            name: 'Title',
            selector: row => row.Title,
            sortable: true
        },

        {
            name: 'Όνομα',
            selector: row =>  row.Text,
            sortable: true
        },
 
        {
            name: 'Date',
            selector: row =>  row.DateCreated.split('T')[0],
            sortable: true
        },        
        {
            name: '',
            selector: row =>  <Button variant="danger" onClick={()=>deleteRecord(row.id)}>X</Button>,
            sortable: true
        },    
        // {
        //     name: 'Default Γλώσσα',
        //     selector: row =>  row.LangName,
        //     sortable: true
        // },  
            
    ]
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        loadData()
    },[])




      const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/push',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
         console.log(res.data.results) 
          setData(res.data.results)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }



      const showModal=(id)=>{
        if (id==0){
            setRecord({ModalTitle:'ΝΕΟ PUSH NOTIFICATION', id:0,Title:''})
            setShow(true)
        }else{
            const recObj=data.filter(item=>item.LangID==id)[0]
            
            setRecord({ModalTitle:recObj.LangName, id:recObj.LangID,Title:recObj.LangName,WelcomeText:recObj.WelcomeText})
            setShow(true)            
        }

      }

      const save=(e)=>{
        e.preventDefault()
        if (window.confirm('Send Push Notification. Are you Sure?')){
            const today = new Date();
            const time = today.toISOString().split('T')[1].substring(0, 8)
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();
        
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
        
            const dateSent = dd + '-' + mm + '-' + yyyy+' '+time
            const obj={...record,dateSent}

            axios.post(process.env.REACT_APP_API_SERVER+'admin/push',obj,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                    }            
                })
              .then(res=>{
                if (res.data.err==0){
                    loadData()
                }
                setShow(false)
                alert(res.data.msg)
            })
        }

      }

      const deleteRecord=(id)=>{
        if (window.confirm('ΔΙΑΓΡΑΦΗ!!!! ΣΙΓΟΥΡΑ???')){
            axios.delete(process.env.REACT_APP_API_SERVER+'admin/push/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                    }            
                })
              .then(res=>{
                if (res.data.err==0){
                    loadData()
                }
                setShow(false)
                alert(res.data.msg)
            })
        }
      }

    return (
        <>
            {isLoading &&
            <Alert variant='warning'>Loading....Please Wait</Alert>

            }

            {!isLoading &&
            <>
            <Alert variant="secondary">
                <table width="100%">
                    <tr>
                        <td><h5><FaBell/> Push Notifications</h5></td>
                        <td align="right">
                        <Button variant="dark" onClick={()=>showModal(0)}><FontAwesomeIcon icon={faPlus}/></Button>
                        </td>
                        <td width="10"></td>
                    </tr>
                </table>
                    
            </Alert>




                <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                />            
            
            </>
            }

                <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
                <Modal.Header closeButton>
                <Modal.Title>
                    {record.ModalTitle}
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                <Form onSubmit={save}>

                        <>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Τίτλος (έως 40 chars)</Form.Label>
                            <Form.Control type="text" maxLength={40} required value={record.Title} onChange={(e)=>setRecord({...record,Title:e.target.value})}/>
                        </Form.Group>
                        <br/><br/>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Κείμενο (έως 250 chars)</Form.Label>
                            <Form.Control type="text" maxLength={250} required value={record.WelcomeText} onChange={(e)=>setRecord({...record,Text:e.target.value})}/>
                        </Form.Group>                        
                        <br/>




                        <Row><Col></Col>
                        <Col md="auto">

                            {record.id>0 &&
                            <>
                            <Button  variant="danger" style={{width:"150px"}} onClick={deleteRecord}>
                                Διαγραφή
                            </Button>
                            &nbsp; 
                            </>
                            }

                            <Button  variant="success" type="submit" style={{width:"150px"}}>
                                ΑΠΟΣΤΟΛΗ
                            </Button>                      
                        </Col>
                      </Row>                        
                        </>
                   </Form> 
                </Modal.Body>

            </Modal>   
        </>
    )
}
