import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Row,Col,Accordion} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import {FaBookOpen, FaUser,FaEdit} from 'react-icons/fa'
import ChallengeFormReview from './ChallengeFormReview';
import moment from 'moment'
import ReactPlayer from 'react-player'

export default function ContentReports() {
    const [form,setForm]=useState(false)
    const [item,setItem]=useState({})
    const [isLoading,setIsLoading]=useState(true)
    const [data,setData]=useState([])
    const [sports,setSports]=useState([])
    const [show, setShow] = useState(false)
    const [content,setContent]=useState({})

    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        {
            name: '',
            selector: row => row.IsActive==1 ? <Button variant="secondary" onClick={()=>handleShow(row)}><FaEdit/></Button> :'',
            sortable: true,
            maxWidth:'5px'
        },

        {
            name: 'Reporting User',
            selector: row => row.ReportingUsrFirstName+ ' '+row.ReportingUsrLastName,
            sortable: true
        },

       

        {
            name: 'Reported User',
            selector: row => row.ReportedUsrFirstName+ ' '+row.ReportedUsrLastName,
            sortable: true
        },

        {
          name: 'Report Date',
          selector: row =>  moment(row.ReportDate).format('DD/MM/YYYY'),
          sortable: true
      }, 

      {
        name: 'Notes',
        selector: row => row.ReportDetail,
        sortable: true
    },

      
    ]


    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'admin/contentreports',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
        // console.log(res.data) 
          setData(res.data)
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }


      const handleClose = () => setShow(false);
      const handleShow = (row) => {
        console.log(row)
        setItem(row)
        loadContent(row)
        setShow(true)
      }

      const loadContent=(row)=>{
        setIsLoading(true)
        axios.post(process.env.REACT_APP_API_SERVER+'admin/contentreports/getcontent',row,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
        if (res.data.err==0){
            setContent(res.data)
        }
        
          setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        })          
      }





      const deactivateUser=(uuid)=>{
        if (window.confirm('DEACTIVATE USER, ARE YOU SURE?')){
                axios.get(process.env.REACT_APP_API_SERVER+'admin/contentreports/deactivateusr/'+uuid,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                        }            
                    })
                .then(res=>{
                console.log(res.data) 
                // setData(res.data)
                    setIsLoading(false)
                    if (res.data.err==0){
                        setShow(false)
                    }else{
                        alert('An error has occured')
                    }
                    
                }).catch(err=>{
                    setIsLoading(false)
                    console.log(err)
                })          

        }
      }


      const deleteUser=(uuid)=>{
        if (window.confirm('DELETE USER, ARE YOU SURE?')){
                axios.post(process.env.REACT_APP_API_SERVER+'admin/contentreports/deleteusr',item,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                        }            
                    })
                .then(res=>{
                //console.log(res.data) 
                // setData(res.data)
                    setIsLoading(false)
                    if (res.data.err==0){
                        setShow(false)
                        loadData()
                    }else{
                        alert('An error has occured')
                    }
                    
                }).catch(err=>{
                    setIsLoading(false)
                    console.log(err)
                })          

        }
      }


      const deleteContent=()=>{
        if (window.confirm('DELETE CONTENT, ARE YOU SURE?')){
            setIsLoading(true)
            axios.post(process.env.REACT_APP_API_SERVER+'admin/contentreports/deletepost',item,{
              headers: {
                  'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
                  }            
              })
            .then(res=>{
                setShow(false)   
                loadData()
              setIsLoading(false)
              
            }).catch(err=>{
                setIsLoading(false)
                console.log(err)
            }) 
        }
      }


    //   const deactivateUser=(uuid)=>{

    //     if (window.confirm('DEACTIVATE USER, ARE YOU SURE?')){
    //         axios.get(process.env.REACT_APP_API_SERVER+'admin/contentreports/deactivateusr/'+uuid,{
    //             headers: {
    //                 'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
    //                 }            
    //             })
    //           .then(res=>{
    //           console.log(res.data) 
    //            // setData(res.data)
    //             setIsLoading(false)
                
    //           }).catch(err=>{
    //               setIsLoading(false)
    //               console.log(err)
    //           })          
    //         }
    //     })

    //   }




  return (
    <>
    {isLoading && 

    <Alert variant="warning" >Loading, please wait....</Alert>
    }

    {!isLoading &&             
    <>
    <Alert variant="secondary"><h4><FaUser/> Content Reports </h4></Alert>
        <Container fluid>

       
        <DataTable
                columns={columns}
                data={data.filter(xitem=>xitem.SportID==item.SportID)}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                /> 

        </Container>


        <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
                <Modal.Header closeButton>
                <Modal.Title>

                {item.ReportingUsrFirstName+ ' '+item.ReportingUsrLastName} Reported &nbsp;
                {item.ReportedUsrFirstName+ ' '+item.ReportedUsrLastName}

                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                      <>
                        <b>Date Created: </b> {moment(content.DateCreated).format('DD/MM/YYYY')}
                        <hr/>
                        {content.Descr}
                        <hr/>
                        
                        {item.ContentType==2 &&
                            <>
                                {content.VideoID.length>0 &&
                                    <>



                                    <ReactPlayer 
                                        url={`${process.env.REACT_APP_VIDEO_BASE_URL}${content.VID}.smil/playlist.m3u8`}
                                        controls={true}
                                        light={process.env.REACT_APP_VIDEOSERVER_API+''+content.VID+'.jpg'}
                                        width='320px'
                                        height='200px'
                                        />                                    
                                    </>
                                }

                                {content.Photo.length>0 &&
                                <>
                                    <img src={process.env.REACT_APP_API_SERVER+'postfiles/'+content.ContentUUID+'/123'}class="challenge-post-photo show" alt="photo" />
                                </>
                                }                                
                            </>
                        }

                        <br/>
                        <hr/>
                        <Button variant="warning" onClick={()=>{deactivateUser(content.UserUUID)}}>Deactivate User</Button> 
                        &nbsp;
                        <Button variant="danger" onClick={()=>{deleteUser(content.UserUUID)}}>Delete User</Button> 
                        &nbsp;
                        <Button variant="dark" onClick={()=>{deleteContent(item.ContentID)}}>Delete Post</Button> 

                        </>
                </Modal.Body>

            </Modal>


        </>
    }
</>
  )
}
