import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import axios from 'axios'
import {FaBookOpen} from 'react-icons/fa'
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FileUploadSponsor from './FileUploadSponsor'
import FileUploadLogoSponsor from './FileUploadLogoSponsor'
import SponsorLang from './SponsorLang'
import SponsorVideos from './SponsorVideos'
import ChallengeUsers from './ChallengeUsers'



export default function SponsorForm(props) {

  const [record,setRecord]=useState({})
  const [isLoading,setIsLoading]=useState(false)
  const [descr,setDescr]=useState('')
  const [sports,setSports]=useState([])
  
  
  useEffect(()=>{
    loadData(props.id)
    loadSports()
  },[props.id])

  const loadData=(id)=>{
    setIsLoading(true)
    if (id!=0){
      axios.get(process.env.REACT_APP_API_SERVER+'admin/sponsors/'+id,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
            }            
        })
      .then(res=>{
        //console.log(res.data.results) 
        setRecord(res.data.results)
        setDescr(res.data.results.Descr)

        setIsLoading(false)
        
      }).catch(err=>{
          setIsLoading(false)

      })
    }else{
      setRecord({
        id: 0,
        IsActive:1,
        Descr:'',
        IsChallenge:0
      })
      setIsLoading(false)
    } 
  }

  const loadSports=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'admin/sports',{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
          }            
      })
    .then(res=>{
       // console.log(res.data.results) 
      setSports(res.data.results)
      setIsLoading(false)
      
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    })          
  }

  const save=(e)=>{
    e.preventDefault()

    const obj={...record}
    console.log(obj)
    axios.post(process.env.REACT_APP_API_SERVER+'admin/sponsors',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
          }            
      })
    .then(res=>{
      alert(res.data.msg)
      props.showForm(0,false)
    }).catch(err=>{
      alert('Υπήρξε ένα πρόβλημα, παρακαλώ προσπαθήστε ξανά')
    })
  }

  const chDescr=(e,editor)=>{
    var xDescr=editor.getData()

   setDescr(xDescr)
}

const deleteRecord=()=>{
   if (window.confirm('ΔΙΑΓΡΑΦΗ ΟΛΟΥ ΤΟY SPONSOR!!!! ΣΙΓΟΥΡΑ???')){
      axios.delete(process.env.REACT_APP_API_SERVER+'admin/sponsors/'+props.id,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
          if (res.data.err==0){
              props.loadData()
             props.showForm(0,false)
          }else{
            alert(res.data.msg)
          }

          
      })
   }
}



const sportsList=sports.map(item=>{
  return(
    <option key={item.SportID} value={item.SportID}>{item.SportName}</option>
  )
})

  return (
    <>
    <br/><br/>
      {isLoading &&
      <Alert variant="warning">Loading...</Alert>
      
      }

      {props.id!=0 && 
      <>
      <FileUploadSponsor id={props.id} type="1" Photo={record.Photo} loadData={loadData} record={record}/>

      <br/><hr/>

      <FileUploadLogoSponsor id={props.id} type="1" Photo={record.Photo} loadData={loadData} record={record}/>

      <br/><hr/>

      </>
      }    
    <Form onSubmit={save}>




            <Form.Group>
                <Form.Label><b>Άθλημα</b></Form.Label>  
                <Form.Control required as="select" value={record.SportID} onChange={(e)=>setRecord({...record,SportID:e.target.value})} style={{width:'200px'}}>
                    <option value=''>[Επιλέξτε Άθλημα]</option>
                    {sportsList}
                </Form.Control>
            </Form.Group>   

            


            <br/>
            <Form.Group controlId="formBasicEmail">
                <Form.Label><b>Τίτλος {record.IsChallenge==1 ? 'Challenge' : 'Άρθρου' }</b></Form.Label>
                <Form.Control type="text"  required value={record.Title} onChange={(e)=>setRecord({...record,Title:e.target.value})}/>
            </Form.Group> 

            <br/>
            <Form.Group>
            <Form.Label><b>Περιγραφή</b></Form.Label>
            <Form.Control as="textarea" rows={3} value={record.Descr} onChange={(e)=>setRecord({...record,Descr:e.target.value})}/>
            {/* <CKEditor
                editor={ClassicEditor}
                data={descr}
                onChange={()=>chDescr()}        

            /> */}

            </Form.Group>
          
            <>
            <br/>
            <Form.Group controlId="formBasicEmail">
                <Form.Label><b>Από</b></Form.Label>
                <table>
                  <tr>
                    <td><Form.Control required type="date" style={{width:'200px'}} value={record.DateFrom} onChange={(e)=>setRecord({...record,DateFrom:e.target.value})}/> </td>

                  </tr>
                </table>
                
            </Form.Group>     

            <br/>
            <Form.Group controlId="formBasicEmail">
                <Form.Label><b>Έως</b></Form.Label>
                <table>
                  <tr>
                    <td><Form.Control required type="date" style={{width:'200px'}} value={record.DateTo} onChange={(e)=>setRecord({...record,DateTo:e.target.value})}/> </td>

                  </tr>
                </table>
                
            </Form.Group>  
            </>

            <br/>
            <Form.Group>
                <Form.Label><b>Ενεργό</b></Form.Label>  
                <Form.Control as="select" value={record.IsActive} onChange={(e)=>setRecord({...record,IsActive:e.target.value})} style={{width:'200px'}}>
                    <option value='1'>ΝΑΙ</option>
                    <option value='0'>ΟΧΙ</option>
                </Form.Control>
            </Form.Group>


            <p align="right">

                {props.id!=0 &&
                <>
                <Button  variant="danger" style={{width:"150px"}} onClick={deleteRecord}>
                    Διαγραφή
                </Button> 
                &nbsp;&nbsp;   
                </>          
                }

                <Button  variant="dark" type="submit" style={{width:"150px"}} >
                    Αποθήκευση
                </Button>
            </p>
    </Form>

    {props.id!=0 && 
    <>
    <hr/>
    <SponsorLang id={props.id} IsChallenge={record.IsChallenge}/>
    </>
    }

  {props.id!=0 && 
    <>
    <hr/>
    <SponsorVideos id={props.id} IsChallenge={record.IsChallenge}/>


    </>
    }

    </>

  )
}
