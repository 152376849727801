import React from 'react'
import './css/style.css'
import Logo from './css/images/logo.png'

export default function SignUp() {
  return (
    <div className="container container-mobile">
      <div className="div-wrap">
        <div
          className="box-image"
          //style="background-image: url(images/Signup3.jpg)"
        >
          <div className="logo"><img src={Logo} alt="logo" /></div>
        </div>
        <div className="form-div">
          <div
            className="form-buttons w-100 justify-content-space-between mb-3 border-grey"
          >
            <button type="button" className="btn w-100 btn-grey">
              <a href="login.html">Login</a>
            </button>
            <button type="button" className="btn w-100 btn-orange">Sign Up</button>
          </div>
          <form method="POST" action="">
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                required
              />
              <label for="floatingInput">Email address</label>
            </div>
            <div className="input-group d-flex mb-3">
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  required
                />
                <label for="floatingPassword">Password</label>
              </div>
              <div
                className="input-group-append d-flex align-items-center password-box"
              >
                <span
                  className="input-group-text password-eye"
                  onclick="password_show_hide();"
                >
                  <i className="bi bi-eye" id="show_eye"></i>
                  <i className="bi bi-eye-slash d-none" id="hide_eye"></i>
                </span>
              </div>
            </div>
            <div className="input-group d-flex mb-3">
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword2"
                  placeholder="Repeat Password"
                  required
                />
                <label for="floatingPassword2">Repeat Password</label>
              </div>
              <div
                className="input-group-append d-flex align-items-center password-box"
              >
                <span
                  className="input-group-text password-eye"
                  onclick="password_show_hide2();"
                >
                  <i className="bi bi-eye" id="show_eye2"></i>
                  <i className="bi bi-eye-slash d-none" id="hide_eye2"></i>
                </span>
              </div>
            </div>
            <div className="form-floating mb-3">
              <input
                type="tel"
                className="form-control"
                id="floatingInput2"
                placeholder="Mobile phone"
                required
              />
              <label for="floatingInput2">Mobile Phone</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput3"
                placeholder="Username"
                required
              />
              <label for="floatingInput3">Username</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="date"
                className="form-control"
                id="floatingInput4"
                placeholder="DD/MM/YY"
              />
              <label for="floatingInput4">Date</label>
            </div>
            <button type="submit" className="btn orange mt-3">Register</button>
          </form>
        </div>
      </div>
      <div className="agree-terms">
        By creating an account you agree to our Terms of Services and Privacy
        Policy
      </div>
    </div>
  )
}
